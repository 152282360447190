<template>
  <v-card>
    <v-card-text>
      <v-progress-linear
        v-if="isReportByPriceHistoryLoading"
        indeterminate
      />
      <highcharts
        v-if="show"
        :chart-data="chartData"
      />
      <v-alert
        v-else
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
      >{{ $t('chartByPriceHistoryTab.warning') }}</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Highcharts from '@/components/Highcharts.vue';
import debounce from 'lodash.debounce';

export default {
  name: 'ChartByPriceHistory',
  data: () => ({
    show: false,
  }),
  components: {
    Highcharts,
  },
  computed: {
    ...mapState(['reportByPriceHistory', 'search', 'isReportByPriceHistoryLoading']),

    debounceRunSearchQuery() {
      return debounce(this.getItems, this.$store.getters.APITimeout);
    },
    chartData() {
      if (!this.reportByPriceHistory.length) {
        return {};
      }
      const preprocessed = {};
      const weeks = {};
      this.reportByPriceHistory.forEach((item) => {
        if (!Object.prototype.hasOwnProperty.call(preprocessed, item.source)) {
          preprocessed[item.source] = {};
        }
        if (!Object.prototype.hasOwnProperty.call(weeks, item.KW)) {
          weeks[item.KW] = [];
        }
        preprocessed[item.source][item.KW] = item.avg_pay_now || item.avg_pay_later || 0;
        weeks[item.KW].push(item.median_pay_now || item.median_pay_later || 0);
      });

      const categories = Object.keys(weeks).sort();
      const series = Object.entries(preprocessed).map(([portal, value]) => {
        const data = categories.map(
          (week) => (Object.prototype.hasOwnProperty.call(value, week) ? value[week] : 0),
        );
        return {
          name: portal,
          data,
        };
      });

      const data = Object.entries(weeks).map(
        // eslint-disable-next-line no-unused-vars
        ([_, array]) => array.sort()[Math.floor(array.length / 2)] || 0,
      );
      series.push({
        name: 'median',
        data,
        marker: { radius: 0 },
        dashStyle: 'LongDash',
      });

      return {
        chart: { type: 'line' },
        title: { text: this.$t('chartByPriceHistoryTab.legend') },
        xAxis: {
          categories: Object.keys(weeks).sort(),
          title: { text: this.$t('chartByPriceHistoryTab.xAxis') },
        },
        yAxis: { title: { text: this.$t('chartByPriceHistoryTab.yAxis') } },
        series,
        legend: { symbolWidth: 40 },
      };
    },
  },
  watch: {
    search: {
      deep: true,
      handler() {
        this.show = this.isPortalSelected();
      },
    },
  },
  mounted() {
    this.show = this.isPortalSelected();
    this.$root.$on('chart-by-history', () => {
      this.debounceRunSearchQuery();
    });
    if (!this.reportByPriceHistory.length) {
      this.debounceRunSearchQuery();
    }
  },
  methods: {
    ...mapActions(['getReportByPriceHistory']),

    getItems() {
      if (!this.show) return;
      this.getReportByPriceHistory({ search: { ...this.search } });
    },
    isPortalSelected() {
      return this.search.portals.length > 0;
    },
  },
};
</script>
